













































































































































import { imageData } from '@/utils/utilsData';
// import API from '@/plugins/axios';
import API from '@/plugins/axios';
import Vue from 'vue';
export default Vue.extend({
  data: () => ({
    imageData: { ...imageData },
    showDialog: false,
    data: {
      id: '',
      name: '',
      description: '',
      image: '',
      file_name: '',
      file_uuid: '',
      maltese: false,
      learning_outcomes: [
        {
          content: '',
          deleted: false,
        },
      ],
      minimum_evidence: [
        {
          content: '',
          deleted: false,
        },
      ],
    },
    dragging: false,
    isUploading: false,
  }),
  computed: {
    learning_outcomes() {
      return this.data.learning_outcomes.filter((l) => !l.deleted);
    },
    minimum_evidence() {
      return this.data.minimum_evidence.filter((l) => !l.deleted);
    },
  },
  methods: {
    addNewEvidence() {
      this.data.minimum_evidence.push({
        content: '',
        deleted: false,
      });
    },
    addNewOutcome() {
      this.data.learning_outcomes.push({
        content: '',
        deleted: false,
      });
    },
    async saveUnit() {
      const unitData = { ...this.data };
      unitData.learning_outcomes = unitData.learning_outcomes.filter(
        (item) => (item.content && !item.deleted) || ((item as unknown as { id: number }).id && item.deleted)
      );
      unitData.minimum_evidence = unitData.minimum_evidence.filter(
        (item) => (item.content && !item.deleted) || ((item as unknown as { id: number }).id && item.deleted)
      );
      const { status } = await API.patch(`/administration/units/${this.$route.params.id}/`, unitData);
      if (status === 200) {
        await this.$router.push(`/admin/unit/${this.$route.params.id}`);
      }
    },
    async deleteSelected() {
      const { status } = await API.delete(`/administration/units/${this.data.id}/`);
      if (status == 200) {
        await this.$router.push(`/admin/`);
      }
    },
    deleteOutcome(index: number) {
      this.learning_outcomes[index].deleted = true;
      this.data.learning_outcomes = [...this.data.learning_outcomes];
    },
    deleteEvidence(index: number) {
      this.minimum_evidence[index].deleted = true;
      this.data.minimum_evidence = [...this.data.minimum_evidence];
    },
    async onDrop(e: DragEvent) {
      if (e.dataTransfer?.files.length) {
        (this.$refs.fileInput as HTMLInputElement).files = e.dataTransfer.files;
        this.isUploading = true;
        const image = (this.$refs.fileInput as HTMLInputElement).files?.[0];
        if (image) {
          const form = new FormData();
          form.append('pdf_file', image);
          const { data } = await API.put('/upload/pdf-file/', form, {
            baseURL: process.env.VUE_APP_API_URL_FILES,
          });
          this.isUploading = false;
          this.data.file_name = data.original_name;
          this.data.file_uuid = data.name;
          console.log(data);
        }
      }
      this.dragging = false;
    },
    async upload() {
      const files = (this.$refs.fileInput as HTMLInputElement).files || ([] as unknown as FileList);
      this.isUploading = true;
      const image = files[0];
      if (image) {
        const form = new FormData();
        form.append('pdf_file', image);
        const { data } = await API.put('/upload/pdf-file/', form, {
          baseURL: process.env.VUE_APP_API_URL_FILES,
        });
        this.isUploading = false;
        this.data.file_name = data.original_name;
        this.data.file_uuid = data.name;
        console.log(data);
      }
    },
    async deletePdf() {
      await API.delete(`/pdf-file/${this.data.file_uuid}/`, {
        baseURL: process.env.VUE_APP_API_URL_FILES,
      });
      this.data.file_name = '';
      this.data.file_uuid = '';
    },
  },
  async mounted() {
    const { data } = await API.get(`/administration/units/${this.$route.params.id}/`);
    this.data = data;
  },
});
